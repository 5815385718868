<template>
  <div class="error-page">
    <div class="error-page__img error-page__img--403"></div>
    <h2 class="error-page__title">Доступ запрещен</h2>
    <p class="error-page__desc">
      У вас нет прав для просмотра данной страницы
    </p>
    <v-btn color="black" dark class="radius-root" x-large @click="$router.push('/')">
      Перейти на Главную
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'Error403',

  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables.scss";

</style>
